import React, { useState } from "react";
import { Box, TextField, Typography , IconButton  } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const Textfield = ({
  name,
  value,
  label,
  type,
  placeholder,
  width,
  getInput,
  errors,
  isDate = false, // Add a prop to determine if it's a date input
  direction,
  inputprops,
  isName,
}) => {
  // Responsive Design
  const { t } = useTranslation();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("md"));

  const lang = localStorage.getItem("lang");

  const handleValue = (e) => {
    const newValue = e.target.value;

    // Regex pattern to match Arabic characters or characters from other non-English languages.
    const arabicPattern = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
    const englishPattern = /[a-zA-Z]/;

    if (lang === "en" && arabicPattern.test(newValue)) {
      alert("English mode: Please do NOT enter Arabic characters.");
      return;
    }

    if (lang === "ar" && englishPattern.test(newValue)) {
      alert("Arabic mode: Please do NOT enter English characters.");
      return;
    }

    getInput(newValue);
  };

  return (
    <section style={{ width: "100%" }}>
      <label
        style={{ fontSize: `${match ? "16px" : "20px"}`, marginLeft: "5px" }}
      >
        {label}
      </label>
      {isDate ? ( // Check if it's a date input
      <Box style={{ position: "relative" }}>
          <TextField
            value={value}
            margin="normal"
            required
            type={type}
            name={name}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            size="big"
            style={{
              width: `${width}`,
              background: "white",
              margin: "5px 0 20px 0",
            }}
            onChange={handleValue}
            inputProps={{ max: new Date().toISOString().split("T")[0] }} // Set the min attribute to today's date
          />
          <style>
          {`
            [name="${name}"] + .MuiInputAdornment-root {
              position: absolute;
              right: 0;
              left: auto;
            }
          `}
        </style>
        </Box>
      ) : (
        <TextField
          value={value}
          margin="normal"
          required
          type={type}
          name={name}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          size="big"
          style={{
            width: `${width}`,
            background: "white",
            margin: "5px 0 20px 0",
          }}
          onChange={handleValue}
          inputProps={isName === true ? inputprops : ""}
        />
      )}

      {isName && (
        <Typography
          style={{
            direction: direction === "ar" ? "rtl" : "ltr",
            fontSize: "12px",
            margin:
              direction === "ar" ? "-15px 10px 20px 0" : "-15px 0 20px 10px",
            color: "#8b6767",
          }}
        >
          {t(
            "The name must contain only letters and must not be less than 10 letters and not more than 30 letters"
          )}
        </Typography>
      )}

      {errors && (
        <p
          style={{
            color: "red",
            direction: direction === "ar" ? "rtl" : "ltr",
            fontSize: "13px",
            margin:
              direction === "ar" ? "-18px 10px 5px 0" : "-18px 0 5px 10px",
          }}
        >
          {errors[name]}
        </p>
      )}
    </section>
  );
};

export default Textfield;
