import Home from "./Pages/Home";
import { useEffect, useState } from "react";
import { useTranslation, I18nextProvider } from "react-i18next";
import SliderImg from "./Components/Chat/Slider";
function App() {
  const [direction, setDirection] = useState("en");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const localStorageLang = localStorage.getItem("lang");
    if (localStorageLang) {
      setDirection(localStorageLang);
      i18n.changeLanguage(localStorageLang);
    }
  }, [i18n]);

  const storeDirec = (lang) => {
    setDirection(lang);
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };



  return (
    <div>
      
      <Home direction={direction} setDirection={storeDirec} />
    </div>
  );
}

export default App;
