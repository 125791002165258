import React, { useState } from "react";
import { Select, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import "./dropDownMenu.css";

const DropDownMenu = ({
  label,
  value,
  data,
  name,
  onSelect,
  errors,
  direction,
  loading,
  disabled,
}) => {
  // Responsive Design
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    onSelect(e.target.value);
  };
  return (
    <section style={{ width: "100%", marginBottom: "10px" }}>
      <div style={{ height: `${match ? "3px" : "12px"}` }}>
        <Typography
          style={{
            fontSize: `${match ? "16px" : "20px"}`,
            marginLeft: "5px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "90%",
            whiteSpace: "nowrap",
          }}
          title={label}
        >
          {label}
        </Typography>
      </div>
      <br />
      <Select
        style={{ marginTop: "2px" }}
        value={value}
        name={name}
        fullWidth
        onChange={handleChange}
        disabled={disabled}
        className={`${direction === "ar" ? "arabic-select" : "english-select"}`}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
      >
        {data?.map((item, idx) => {
          if (direction === "en") {
            return (
              <MenuItem
                key={idx}
                value={item?.menuValueId || item?.serviceId || item?.clinicId}
                style={{ direction: "ltr" }}
              >
                {item?.menuEN?.valueEN || item?.serviceEN || item?.clinicEN}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={idx}
                value={item?.menuValueId || item?.serviceId || item?.clinicId}
                style={{ direction: "rtl" }}
              >
                {item?.menu?.value || item?.service || item?.clinic}
              </MenuItem>
            );
          }
        })}
      </Select>
      {errors && (
        <p
          style={{
            color: "red",
            direction: direction === "ar" ? "rtl" : "ltr",
            fontSize: "13px",
            margin: direction === "ar" ? "5px 10px 0 0" : "5px 0 0 10px",
          }}
        >
          {errors[name]}
        </p>
      )}
    </section>
  );
};

export default DropDownMenu;
