import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MediaCard from "./card";
const SliderCards = ({ img_one, img_tow, img_three, img_four }) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
  };
  // return (
  //   <div style={{ width: "250px" }}>
  //     <Slider {...settings}>
  //       {img_one && (
  //         <div>
  //           <MediaCard
  //             img={img_one && img_one}
  //             title="Facial Laser"
  //             price="SR 1,600"
  //             button="View our track record"
  //           />
  //         </div>
  //       )}

  //       {img_tow && (
  //         <div>
  //           <MediaCard
  //             img={img_tow && img_tow}
  //             title="Lips Filler"
  //             price="SR 1,600"
  //             button="View our track record"
  //           />
  //         </div>
  //       )}

  //       {img_three && (
  //         <div>
  //           <MediaCard
  //             img={img_three && img_three}
  //             title=" Khulood Saleh"
  //             // price="SR 1,600"
  //             // button="View our track record"
  //           />
  //         </div>
  //       )}
  //       {img_four && (
  //         <div>
  //           <MediaCard
  //             img={img_four && img_four}
  //             title="tarek kashbash "
  //             // price="SR 1,600"
  //             // button="View our track record"
  //           />
  //         </div>
  //       )}
  //     </Slider>
  //   </div>
  // );
};

export default SliderCards;
