import React from "react";
import ChatBot from "react-simple-chatbot";
import classes from "../../Styles/chat.module.css";
import SliderCards from "./Slider";
import { useTranslation } from "react-i18next";
import {
  LaserTreatment,
  FacialLaser,
  IMG_8703,
  lipaugmentation,
  khlood,
  Louie,
  logo,
} from "../../assets/index";
import SliderImgs from "./SliderImgs";
import ChatForm from "./ChatForm";
import "./style/chat.css";
const InterviewChatbot = () => {
  const { t } = useTranslation();
  const steps = [
    {
      id: "1",
      message: t(
        "Hello ! Welcome to Dr. Musaed Al Zallal Clinics. What can I do to reveal your beauty? "
      ),
      trigger: "options",
    },
    {
      id: "options",
      options: [
        {
          value: "appointments",
          label: t("Book An Appointment"),
          trigger: "gendar",
        },
        {
          value: "Services",
          label: t("View our Services"),
          trigger: "gendar",
        },
        { value: "Branches", label: t("Our Branches"), trigger: "Branches" },
        {
          value: "Opening Hours",
          label: t("Opening Hours"),
          trigger: "Dermatology & Laser",
        },
        {
          value: "Know more about Us",
          label: t("Know more about Us "),
          trigger: "Dermatology & Laser",          
        },
      ],
    },
    {
      id: "gendar",
      options: [
        { value: "Male ", label: t("male"), trigger: "select department" },
        { value: "Famale ", label: t("Famale"), trigger: "select department" },
      ],
    },
    {
      id: "select department",
      message: t("Please select the department"),
      trigger: "department",
    },

    {
      id: "department",
      options: [
        {
          value: "Dermatology & Laser ",
          label: t("Dermatology & Laser"),
          trigger: "Dermatology & Laser",
        },
        {
          value: "Facial Laser (SR XXX) ",
          label: t("Facial Laser (SR XXX)"),
          trigger: "Dermatology & Laser",
        },
        { value: "View our track record",
         label: t("View our track record") ,
         trigger: "Dermatology & Laser",

        },
      ],
    },
    {
      id: "Dermatology & Laser",
      component: (
        <div style={{ backgroundColor: "#e7eaec" }}>
          <SliderCards img_one={LaserTreatment} img_tow={FacialLaser} />
        </div>
      ),
      trigger: "Facial Laser - View our track record",
    },
    {
      id: "Facial Laser - View our track record",
      options: [
        {
          value: "Facial Laser - View our track record",
          label: t("View our track record"),
          trigger: "showLider Facial Laser - View our track record",
        },
        {
          value: "Go back to main meun",
          label: t("Go back to main menu"),
          trigger: "options",
        },
      ],
    },
    {
      id: "showLider Facial Laser - View our track record",
      component: (
        <div style={{ backgroundColor: "#e7eaec" }}>
          <SliderImgs img_one={IMG_8703} img_tow={lipaugmentation} />
        </div>
      ),
      trigger: "explore all doctors",
    },
    {
      id: "explore all doctors",
      options: [
        {
          value: "Explore all doctors",
          label: t("explore all doctors"),
          trigger: "show all doctors",
        },
        {
          value: "Go back to main meun",
          label: t("Go back to main menu"),
          trigger: "options",
        },
      ],
    },
    {
      id: "show all doctors",
      // asMessage:true,
      component: (
        <div style={{ backgroundColor: "#e7eaec" }}>
          <SliderCards img_three={khlood} img_four={Louie} />
        </div>
      ),
      trigger: "Dermatology & Laser",

    },
    {
      id: "book an appointment all doctors",
      options: [
        {
          value: "book an appointment ",
          label: t("book an appointment"),
          trigger: "show form",
        },
      ],
    },
    {
      id: "show form",
      component: (
        <div>
          <ChatForm />
        </div>
      ),
    },
    // =======================
    {
      id: "Know more about Us",
      component: <a href="https://www.facebook.com/">feacebook</a>,
    },
    {
      id: "Opening Hours",
      component: (
        <div className={classes.openingHours}>
          <h4>
            As much as we would like to serve you around the clock. But our
            working hours are as follows:
          </h4>
          <ul>
            <li>Daily: 9:00 AM – 9:30 PM</li>
            <li>Thursday: 9:00 AM -9:00 PM </li>
          </ul>
        </div>
      ),
    },
    {
      id: "Branches",
      message: t("Select the branch closest to you"),
      trigger: "myBranches",
    },

    {
      id: "myBranches",
      options: [
        { value: "Al Khobar", label: t("Al Khobar"),           trigger: "Dermatology & Laser",
      },
        { value: "Riyadh", label: t("Riyadh"), trigger: "Dermatology & Laser", },
      ],
    },
    {
      id: "Al Khobar",
      message: t("Select the branch closest to you"),
      trigger: "khobar",
    },
    {
      id: "khobar",
      component: (
        <div>
          <a
            href="https://clinicform.niyat.sa/"
            target={"_blank"}
            rel="noreferrer"
          >
            link
          </a>{" "}
        </div>
      ),
    },
    {
      id: "Riyadh",
      message: t("Select the branch closest to you"),
      // trigger:"Riyadh links",
      trigger: () => {
        // Define your custom onClick function here
        // sendtobackend('Riyadh links');
        return "Riyadh links"; // You can specify the next trigger or step
      },
      // trigger: ({ value, steps }) => {
      //   sendtobackend(value,steps);
      // },
    },
    {
      id: "Riyadh links",
      component: (
        <div className={classes.Riyadh_links}>
          <ul>
            <li>
              <a
                href="https://clinicform.niyat.sa/"
                target={"_blank"}
                rel="noreferrer"
              >
                Al Rawdah Branch
              </a>{" "}
            </li>
            <li>
              {" "}
              <a
                href="https://clinicform.niyat.sa/"
                target={"_blank"}
                rel="noreferrer"
              >
                King Abdullah Branch{" "}
              </a>{" "}
            </li>
            <li>
              <a
                href="https://clinicform.niyat.sa/"
                target={"_blank"}
                rel="noreferrer"
              >
                Olaya Branch{" "}
              </a>{" "}
            </li>
            <li>
              <a
                href="https://clinicform.niyat.sa/"
                target={"_blank"}
                rel="noreferrer"
              >
                Badiah Branch{" "}
              </a>{" "}
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "Riyadh links",
      component: <div className={classes.Riyadh_links}></div>,
    },
  ];

  const updatedSteps = steps.map((step, index) => {
    // Clone the step to avoid modifying the original object
    const updatedStep = { ...step };

    updatedStep.component = (
      <div style={{ backgroundColor: "#e7eaec" }}>
        <SliderCards img_one={LaserTreatment} img_tow={FacialLaser} />
      </div>
    );

    return updatedStep;
  });

  return steps.length > 0 ? (
    <ChatBot steps={updatedSteps} botAvatar={logo} headerTitle="Niyat-نياط" />
  ) : (
    <div
      style={{
        color: "white",
        zIndex: "1000",
        backgroundColor: "#3700ff75",
        padding: "8px",
        borderRadius: "5px",
      }}
    >
      {t("Loading...")}
    </div>
  );
};

export default InterviewChatbot;
