//test push 
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Textfield from "../Textfield";
import DropDownMenu from "../DropDownMenu";
import "react-phone-number-input/style.css";

import { useTranslation } from "react-i18next";
import { getCategory } from "../../HelperFn/getCategory";
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import classes from "../../Styles/form.module.css";
import SaudiFlag from "../../assets/saudi-arabia.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./forms.css";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Form = ({ direction, data }) => {

 

  const [isNameValid, setIsNameValid] = useState(true);
  const validateName = (name) => {
    // Define a regular expression pattern for letters and spaces
    const arabicAndEnglishPattern = /^[\u0600-\u06FF\s]+|[a-zA-Z\s]+$/;
    // Check if the input matches the pattern
    const isValidName = arabicAndEnglishPattern.test(name.trim());
    let isValidLenght = false;
    if (name.length <= 30 && name.length >= 10) {
      isValidLenght = true;
    }
    if (isValidName === true && isValidLenght === true) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  };

  //const id=useParams()
  var idnow;
  const hash = window.location.hash;
  const variables = hash.substring(2); // Remove the initial '/#'
  // console.log(variables);
  if (variables == null) {
    // console.log("hi undefiend");
    idnow = null;
  } else {
    idnow = variables;
    // console.log(variables);
  }

  // Responsive Design
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("md"));

  // Get The Language from Local Storage
  const { t } = useTranslation();

  const [phoneValue, setPhoneValue] = useState("");
  const [isValid, setIsValid] = useState(true);

  const validatePhoneNumber = (e) => {
    console.log("wayny anta bt3ml eh", e.target.value);
    const inputPhoneNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    // Define a regular expression pattern for Saudi Arabia phone numbers
    const saudiPhoneNumberPattern = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
    // Check if the input matches the pattern
    const isValidPhoneNumber = saudiPhoneNumberPattern.test(inputPhoneNumber);
    setPhoneValue(inputPhoneNumber);
    setIsValid(isValidPhoneNumber);
  };

  const validatePhoneNum = (phone) => {
    const inputPhoneNumber = phone.replace(/\D/g, ""); // Remove non-numeric characters
    // Define a regular expression pattern for Saudi Arabia phone numbers
    const saudiPhoneNumberPattern = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
    // Check if the input matches the pattern
    const isValidPhoneNumber = saudiPhoneNumberPattern.test(inputPhoneNumber);
    setPhoneValue(inputPhoneNumber);
    setIsValid(isValidPhoneNumber);
  };

  const [textfieldValues, setTextfieldValues] = useState({
    name: "",
  });
  const [gender, setGender] = useState("");
  const [errors, setErrors] = useState({});
  const [captcha, setRecaptcha] = useState("");
  const [maxLength, setMaxlength] = useState("");

  const [dropdownValues, setDropdownValues] = useState({
    city: "",
    clinic: "",
    service: "",
    nearestBranch: "",
    communicationTime: "",
    knowAboutus: "",
  });

  const handleDropdownSelect = (name, value) => {
    setDropdownValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleTextFieldValues = (name, value) => {
    // Validate the name when it changes
    if (name === "name") {
      validateName(value);
    }
    setTextfieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCaptcha = (value) => {
    setRecaptcha(value);
  };

  const [serviceData, setServiceData] = useState([]);
  const [clinicsData, setClinicsData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const datePickerRef = useRef(null);

  const openDatePicker = () => {
    // Use the focus method to open the DatePicker
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/Menu/Services/${dropdownValues?.clinic}`
        );

        if (response.ok) {
          const data = await response.json();
          setServiceData(data);
          setLoading(false);
        } else {
          console.error("Response not OK");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dropdownValues?.clinic]);

  useEffect(() => {
    const fetchData = async () => {
      // console.log(dropdownValues?.city);
      try {
        const response = await fetch(
          `${baseUrl}/Menu/Clinics/${dropdownValues?.city}`
        );

        if (response.ok) {
          const data = await response.json();
          setClinicsData(data);
          setLoading(false);
        } else {
          console.error("Response not OK");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dropdownValues?.city]);

  // Update error messages when direction changes
  useEffect(() => {
    const updatedErrors = { ...errors };
    Object.keys(updatedErrors).forEach((key) => {
      updatedErrors[key] = direction === "en" ? "Field Required" : "حقل الزامي";
    });
    setErrors(updatedErrors);
  }, [direction, errors]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the name before submitting the form
    validateName(textfieldValues.name);
    console.log("waryny al name kdh", textfieldValues.name);
    validatePhoneNum(phoneValue);

    const data = {
      name: textfieldValues.name,
      birthDate: startDate,
      gender: gender,
      direction: direction,
      phoneNumber: phoneValue,
      cityId: dropdownValues.city,
      clinicId: dropdownValues.clinic,
      serviceId: dropdownValues.service,
      nearestBranchId: dropdownValues.nearestBranch,
      communicationTimeId: dropdownValues.communicationTime,
      knowAboutusId: dropdownValues.knowAboutus,
    };

    const newErrors = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value === "" || value === null) {
        newErrors[key] = direction === "en" ? "Field Required" : "حقل الزامي";
        console.log("yarb", newErrors[key]);
      }
    });
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      // Scroll to the first error
      const firstErrorKey = Object.keys(newErrors)[0];
      const element = document.getElementsByName(firstErrorKey)[0];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return; // Stop form submission if there are errors
    }

    if (
      Object.keys(newErrors).length === 0 &&
      isNameValid === false &&
      isValid === false
    ) {
      const element = document.getElementsByName("name")[0];
      console.log("element", element);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    if (
      Object.keys(newErrors).length === 0 &&
      isNameValid === true &&
      isValid === false
    ) {
      const element = document.getElementsByName("phoneNumber")[0];
      console.log("element", element);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    const marketeriddata = {
      PatientMarkterId: null,
      MarkterId: idnow,
    };

    if (
      Object.keys(newErrors).length === 0 &&
      isNameValid === true &&
      isValid === true
    ) {
      data.phoneNumber = `+966${data.phoneNumber}`;
      console.log("waryny al data kdh", data);
      const res = await fetch(`${baseUrl}/Patient/Patient`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      }).then(async (response) => {
        const { data } = await axios.get(
          `https://clinicapi.mygatein.com/Markter/Form/GetMarkterId?iddata=${JSON.stringify(
            marketeriddata
          )}`
        );
      });

      setPhoneValue("");
      setGender("");
      setDropdownValues({
        city: "",
        clinic: "",
        service: "",
        nearestBranch: "",
        communicationTime: "",
        knowAboutus: "",
      });
      setStartDate(null);
      setTextfieldValues({
        name: "",
      });
      toast.success(t("Data Has Been Sent Successfully"), {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    // setErrorMessage(t("The number must start with 5 and be 9 digits"))
  };

  return (
    <section
      className={classes.container}
      style={{ direction: `${direction === "ar" ? "rtl" : "ltr"}` }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          noValidate
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px",
            }}
          >
            <Textfield
              direction={direction}
              value={textfieldValues.name}
              name="name"
              type="text"
              label={t("Name")}
              placeholder={t("Name")}
              width="100%"
              getInput={(value) => handleTextFieldValues("name", value)}
              errors={errors}
              inputprops={{ maxLength: 30 }}
              isName={true}
            />

            {!isNameValid && textfieldValues.name.length !== 0 && (
              <p
                style={{
                  color: "red",
                  direction: direction === "ar" ? "rtl" : "ltr",
                  fontSize: "13px",
                  margin: direction === "ar" ? "0 10px 0 0" : "0 0 0 10px",
                }}
              >
                {t("Invalid Name")}
              </p>
            )}
          </Box>

          {/* Phone Number & Birth Date */}
          <section className={classes.splitTwoInputs}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <label
                style={{
                  fontSize: `${match ? "16px" : "20px"}`,
                  marginLeft: "5px",
                }}
              >
                {t("Phone Number")}
              </label>
              <TextField
                variant="outlined"
                value={phoneValue}
                name="phoneNumber"
                onChange={validatePhoneNumber}
                InputProps={{
                  startAdornment: (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "0",
                        borderRight:
                          direction === "en"
                            ? "1px solid black"
                            : "1px solid black",

                        marginRight: "10px",
                      }}
                    >
                      <img
                        alt="Saudi Arabia Flag"
                        src={SaudiFlag}
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: direction === "en" ? "10px" : "10px",
                        }}
                      />
                      <Typography
                        style={{
                          paddingRight: direction === "en" ? "10px" : "10px",
                        }}
                      >
                        {direction === "en" ? "+966" : "+٩٦٦"}
                      </Typography>
                    </Box>
                  ),
                }}
                style={{
                  marginTop: "5px",
                  backgroundColor: "white",
                  fontSize: "15px",
                  direction: direction === "ar" ? "ltr" : "ltr",
                }}
              ></TextField>

              <Typography
                style={{
                  direction: direction === "ar" ? "rtl" : "ltr",
                  fontSize: "12px",
                  margin: direction === "ar" ? "2px 10px 0 0" : "2px 0 0 10px",
                  color: "#8b6767",
                }}
              >
                {t("The number must start with 5 and be 9 digits")}
              </Typography>

              {errors?.phoneNumber && (
                <p
                  style={{
                    color: "red",
                    direction: direction === "ar" ? "rtl" : "ltr",
                    fontSize: "13px",
                    margin: direction === "ar" ? "0 10px 0 0" : "0 0 0 10px",
                  }}
                >
                  {errors.phoneNumber}
                </p>
              )}

              {!isValid && phoneValue !== "" && (
                <p
                  style={{
                    color: "red",
                    direction: direction === "ar" ? "rtl" : "ltr",
                    fontSize: "13px",
                    margin:
                      direction === "ar"
                        ? "3px 10px 10px 0"
                        : "3px 0 10px 10px",
                  }}
                >
                  {t("Invalid Phone Number")}
                </p>
              )}
            </div>


            {/*birth day */}
            <Box
              className={classes.resDate}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <label
                style={{
                  fontSize: `${match ? "16px" : "20px"}`,
                  marginLeft: "5px",
                }}
              >
                {t("Birth date")}
              </label>


              <Box
                className={`${
                  direction === "ar" ? "arabic-select" : "english-select"
                }`}
              >
                <CalendarMonthIcon
                  style={{ cursor: "pointer" }}
                  className="my-calender"
                  onClick={openDatePicker}
                ></CalendarMonthIcon>

                <DatePicker
                  ref={datePickerRef}
                  name="birthDate"
                  todayButton="Today"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText={
                    direction === "ar" ? "شهر/يوم/سنة" : "mm/dd/yyyy"
                  }
                  maxDate={new Date()}
                  style={{ fontSize: "20px", }}
                />
              </Box>

              
              {errors?.birthDate && (
                <p
                  style={{
                    color: "red",
                    direction: direction === "ar" ? "rtl" : "ltr",
                    fontSize: "13px",
                    margin:
                      direction === "ar"
                        ? "5px 10px 20px 0"
                        : "5px 0 20px 10px",
                  }}
                >
                  {errors.birthDate}
                </p>
              )}
            </Box>



          </section>

          {/* Male OR Female */}
          <Box style={{ marginTop: "15px" }}>
            <label style={{ fontSize: "20px", marginLeft: "5px" }}>
              {t("Gender")}
            </label>
            <Box
              style={{
                width: "100%",
                background: "white",
                marginTop: "5px",
                padding: "20px",
                borderRadius: "5px",
                marginBottom: errors?.gender ? "0" : "20px",
              }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  style={{ margin: "0" }}
                  value={t("Female")}
                  control={<Radio />}
                  label={t("Female")}
                />
                <FormControlLabel
                  style={{ margin: "0" }}
                  value={t("Male")}
                  control={<Radio />}
                  label={t("Male")}
                />
              </RadioGroup>
            </Box>

            {errors?.gender && (
              <p
                style={{
                  color: "red",
                  direction: direction === "ar" ? "rtl" : "ltr",
                  fontSize: "13px",
                  margin:
                    direction === "ar" ? "5px 10px 20px 0" : "5px 0 20px 10px",
                }}
              >
                {errors.gender}
              </p>
            )}
          </Box>

          {/* Drop Down Menus */}
          <section className={classes.dropDownMenus}>
            {/* done */}
            <DropDownMenu
              direction={direction}
              value={dropdownValues.city}
              label={t("City")}
              data={getCategory(data, 3, direction)}
              //data={clincs}
              onSelect={(id) => handleDropdownSelect("city", id)}
              name="cityId"
              errors={errors}
            />

            {/* done */}
            <DropDownMenu
              direction={direction}
              value={dropdownValues.clinic}
              label={t("Choose the clinic")}
              // data={getCategory(data, 2, direction)}
              data={clinicsData}
              // data={clincs}
              onSelect={(id) => handleDropdownSelect("clinic", id)}
              name="clinicId"
              errors={errors}
              disabled={!dropdownValues.city} // Disable if the city is not selected
            />

            {/* done */}
            <DropDownMenu
              direction={direction}
              value={dropdownValues.service}
              label={t("Select the service")}
              data={serviceData}
              loading={isLoading}
              onSelect={(id) => handleDropdownSelect("service", id)}
              name="serviceId"
              errors={errors}
              disabled={!dropdownValues.clinic} // Disable if the city is not selected
            />

            {/* done */}
            <DropDownMenu
              direction={direction}
              value={dropdownValues.nearestBranch}
              label={t("Your nearest branch")}
              // data={clincs}
              // data={getCategory(data, 4, direction)}
              data={clinicsData}
              onSelect={(id) => handleDropdownSelect("nearestBranch", id)}
              name="nearestBranchId"
              errors={errors}
              disabled={!dropdownValues.service} // Disable if the city is not selected
            />

            {/* done */}
            <DropDownMenu
              direction={direction}
              value={dropdownValues.communicationTime}
              label={t("Preferred contact times")}
              data={getCategory(data, 6, direction)}
              //data={clincs}
              onSelect={(id) => handleDropdownSelect("communicationTime", id)}
              name="communicationTimeId"
              errors={errors}
            />

            {/* done */}
            <DropDownMenu
              direction={direction}
              value={dropdownValues.knowAboutus}
              label={t("How did you get to know us?")}
              data={getCategory(data, 5, direction)}
              //data={clincs}
              onSelect={(id) => handleDropdownSelect("knowAboutus", id)}
              name="knowAboutusId"
              errors={errors}
            />
          </section>

          <div className={classes.captcha}>
            <ReCAPTCHA
              key={direction}
              hl={direction === "ar" ? "ar" : "en"}
              sitekey="6LciKMMnAAAAANO9jVtATho3jqInfT1mckWXofyu"
              onChange={handleCaptcha}
            />
          </div>

          <section className={classes.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
              disabled={!captcha}
            >
              {t("Send")}
            </Button>
          </section>
        </Box>
      </Box>
      <ToastContainer />
    </section>
  );
};

export default Form;
